import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import SimpleCard from './SimpleCard.vue';
export default {
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      var _component_el_col = _resolveComponent("el-col");

      var _component_el_row = _resolveComponent("el-row");

      return _openBlock(), _createBlock(_component_el_row, {
        class: "section-row"
      }, {
        default: _withCtx(function () {
          return [_createVNode(SimpleCard, null, {
            default: _withCtx(function () {
              return [_createVNode(_component_el_row, {
                align: "top",
                justify: "space-between",
                style: {
                  "padding": "20px"
                }
              }, {
                default: _withCtx(function () {
                  return [_createVNode(_component_el_col, {
                    sm: 20,
                    md: {
                      span: 8
                    }
                  }, {
                    default: _withCtx(function () {
                      return [_renderSlot(_ctx.$slots, "col1")];
                    }),
                    _: 3
                  }), _createVNode(_component_el_col, {
                    sm: 20,
                    md: {
                      span: 12
                    }
                  }, {
                    default: _withCtx(function () {
                      return [_renderSlot(_ctx.$slots, "col2")];
                    }),
                    _: 3
                  })];
                }),
                _: 3
              })];
            }),
            _: 3
          })];
        }),
        _: 3
      });
    };
  }
};